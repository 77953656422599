import React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const AdsSlot = dynamic(() => import('./AdsSlot'), {
    ssr: false
});

interface Props {
    id?: string;
}

const Container = styled.div`
    width: 750px;
    height: 150px;
    margin: auto;
    @media (max-width: 992px) {
        width: 300px;
        height: 200px;
    }
`;

const Billboard = ({ id }: Props): JSX.Element => {
    if (!id) return null;
    return (
        <Container key={`billboard-${id}`}>
            <AdsSlot slotId={id} />
        </Container>
    );
};

export default Billboard;
